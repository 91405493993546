import React, {Component} from 'react';
import '../css/App.css';
import '../css/SegmentView.css';
import '../css/components/NavigationContainer.css';

import history from '../history';
import {Redirect} from 'react-router';
import GridView from '../components/GridView';
import ContactUs from '../components/ContactUs';

//components
import SectionControl from '../components/SectionControl';
import SegmentControl from '../components/SegmentControl';

//section views
import OverviewSection from '../segment_sections/OverviewSection';
import TopicsSection from '../segment_sections/TopicsSection';
import CharacteristicsSection from '../segment_sections/CharacteristicsSection';
import GeographySection from '../segment_sections/GeographySection';
import TimeSection from '../segment_sections/TimeSection';
import CommerceSection from '../segment_sections/CommerceSection';
import FooterSection from '../segment_sections/FooterSection'


//import segmentsMetadata from "../segments_metadata.json";

var segmentsList;
var PubSub = require('../pub_sub');
var request = require('request');
var zlib = require('zlib');

var metadataURL = "https://s3.amazonaws.com/spatial-cdn/taxonomy-api/segments_metadata.json.min.gz";

var shareTitle = "Spatial.ai's Geosocal Taxonomy";

var scrollPosition = 0;
var supportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: ()=> {
      supportsPassive = true;
    }
  });
  window.addEventListener("testPassive", null, opts);
  window.removeEventListener("testPassive", null, opts);
} catch (e) {}


var ua = window.navigator.userAgent;
/* MSIE used to detect old browsers and Trident used to newer ones*/
var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;


class SegmentView extends Component {
  constructor(props){
    super(props);

    this.state = {
      toHomeView: false,
      navigationState: "",
      topBarState: "",
      contactState: ""
    }
  }

  render (){
    if (is_ie) {
     var destination = {
       pathname: 'unsupported',
     }
     return (
       <Redirect to={destination} />
     )
    }

    if (this.state.toHomeView) {
      var destination = {
        pathname: '/',
      }
      history.push(destination)
      return (
        <Redirect to={destination} />
      )
    }
    if (this.state.selectedSegmentIndex !== undefined) {
      return (
        <div id = "segment_view" className = "segment-view">
          <div id = "top_bar" className = {"top-bar " + this.state.navigationState + " " + this.state.topBarState} >
            <div className = "nav-bar">
              <a href= "https://taxonomy.spatial.ai/">
                <img className = "logo" alt = "Spatial.ai Taxonomy Home Page" src="/img/logotype_light.png"/>
              </a>
              <button id = "grid_view_icon" className = "home-button" onClick = {()=>{
                PubSub.publish.show_grid_view();
              }}>
              </button>

              <div className = "nav-container">
                <button id = "sample_data_cta" className = "nav-button sample sp-b-2" onClick = {()=>{
                  window.location.href="https://www.spatial.ai/sample-data-download";
                }}>
                  <img className = "icon" src ="./img/icons/download.svg"/>
                  <div className = "nav-title">
                    sample data
                  </div>
                </button>
                <button id = "share_cta" className = "nav-button sp-b-2" onClick = {()=>{
                  document.getElementById("share_section").scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                  });
                }}>
                  <img className = "icon" src ="./img/icons/share.svg"/>
                  <div className = "nav-title">
                    share
                  </div>
                </button>
                <button id = "contact_us_cta" className = "nav-button sp-b-2" onClick = {()=>{
                  window.location.href="https://www.spatial.ai/contact";
                }}>
                  <img className = "icon" src ="./img/icons/mail.svg"/>
                    <div className = "nav-title">
                      contact us
                    </div>
                </button>
              </div>
            </div>
            <SectionControl label = {segmentsList[this.state.selectedSegmentIndex].label}/>
          </div>
          <div id = "segment_background" className = "segment-background">
            <picture alt = "" key = {"overview_image_" + segmentsList[this.state.selectedSegmentIndex].id}>
              <source media = "(min-width: 769px)" srcSet = {"/img/segment_images/" + segmentsList[this.state.selectedSegmentIndex].id + ".jpg"}/>
              <img alt = {segmentsList[this.state.selectedSegmentIndex].label + "Overview Image"} className ="segment-image" src = {"/img/segment_images_min/" + segmentsList[this.state.selectedSegmentIndex].id + "_min.jpg"}>
              </img>
            </picture>
            <div id = "segment_blur" className = "blur"/>
            <div className = "segment-overlay"/>
          </div>
          <div id = "section_views_container" className = "sections-views-container">
            <OverviewSection segment = {segmentsList[this.state.selectedSegmentIndex]} />
            <TopicsSection topics = {segmentsList[this.state.selectedSegmentIndex].sections.topics}/>
            <GeographySection id = {segmentsList[this.state.selectedSegmentIndex].id} geography = {segmentsList[this.state.selectedSegmentIndex].sections.geography}/>
            <CharacteristicsSection characteristics = {segmentsList[this.state.selectedSegmentIndex].sections.characteristics}/>
            <TimeSection time = {segmentsList[this.state.selectedSegmentIndex].sections.time}/>
            <CommerceSection commerce  = {segmentsList[this.state.selectedSegmentIndex].sections.commerce}/>
            <FooterSection/>
          </div>
          <SegmentControl info = {{
            prevSegment: this.state.prevSegment,
            nextSegment: this.state.nextSegment
          }}/>
          {this.state.contactState}
          <GridView segments = {segmentsList}/>
        </div>
      );
    }
    else {
      return(
        <div id="loading_container" className = "loading-conatiner">
          <img alt = "Loading some good stuff!"className = "loading-icon" src="/img/misc/loader.svg"/>
        </div>
      )
    }
  }

  componentDidMount(){
    if (!is_ie) {
      this.getSegmentData();
    }
  }

  componentDidUpdate(prevProps, prevState){
    if (!is_ie) {
      if (prevState.selectedSegmentIndex === undefined) {
        this.animationObserver();

        window.addEventListener("keydown", this.arrowListener.bind(this), false);
        document.getElementById("segment_view").addEventListener("scroll", this.detectScroll.bind(this), ((supportsPassive)? { passive: true } : false));

        PubSub.subscribe.segment_selected((event, data)=>{
          history.push({
            pathname: '/segments',
            search: '?segment=' + data
          })
          this.updateSegmentPage(data)
        });

        PubSub.subscribe.share_selected((event, data)=>{
          this.sharingHandler(data);
        });

        PubSub.subscribe.contact_displayed(()=>{
          this.setState({
            contactState: ((this.state.contactState === "")? <ContactUs /> : "")
          })
        })
      }

      var tempID = new URL(window.location).searchParams.get("segment");
      if (tempID !== null) {
        if(tempID.toUpperCase() !== segmentsList[this.state.selectedSegmentIndex].id){
          this.updateSegmentPage(tempID.toUpperCase());
        }
      }
    }
  }

  updateSegmentPage(id){
    var tempIndex =  segmentsList.findIndex(segment => segment.id === id)
    if (tempIndex >= 0 && tempIndex < segmentsList.length && tempIndex !== this.state.selectedSegmentIndex) {
      var prevIndex = (tempIndex === 0)? segmentsList.length - 1 : tempIndex - 1;
      var nextIndex = (tempIndex === segmentsList.length - 1)? 0 : tempIndex + 1;

      document.getElementById("segment_view").scrollTo({top: 0, left: 0});

      this.setState({
        selectedSegmentIndex: tempIndex,
        prevSegment: {
          label: segmentsList[prevIndex].label,
          id: segmentsList[prevIndex].id,
          description: segmentsList[prevIndex].sections.overview.description
        },
        nextSegment: {
          label: segmentsList[nextIndex].label,
          id: segmentsList[nextIndex].id,
          description: segmentsList[nextIndex].sections.overview.description
        }
      });
    }
  }

  animationObserver(){
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    }
    var callback = ((entries, observer)=> {
       entries.forEach(entry => {
         if (entry.isIntersecting) {
           if (entry.target.classList.contains("svg-container")) {
             document.querySelector(".svg-path").classList.add("draw-path");
           }
           else if (entry.target.classList.contains("ani-1")) {
             entry.target.classList.add("fade-in");
           }
           else if (entry.target.classList.contains("ani-2")) {
             entry.target.classList.add("grow");
             entry.target.classList.add("y");
           }
           else if (entry.target.classList.contains("ani-3")) {
             entry.target.classList.add("grow");
             entry.target.classList.add("x");
           }
           else if (entry.target.classList.contains("ani-4")) {
             entry.target.children[0].classList.add("slide-in");
           }
           else if (entry.target.classList.contains("trans-1")) {
             entry.target.classList.remove("trans-1");
             entry.target.style.transitionDelay = "0ms"
           }
           else if (entry.target.classList.contains("dollar-container")){
             var index = entry.target.children[1].id.substring(7);
              setTimeout(()=>{
                entry.target.children[1].classList.add("slide-up");
              }, 200 * index);
           }
           observer.unobserve(entry.target)
         }
       });
    });
    var observer = new IntersectionObserver(callback, options);

    observer.observe(document.querySelector(".svg-container"));
    observer.observe(document.querySelector(".sp-h-1"));

    var fadeInElements = document.getElementsByClassName("ani-1");
    Object.values(fadeInElements).map((element, index)=>{
      observer.observe(element)
    });

    var growElementsY = document.getElementsByClassName("ani-2");
    Object.values(growElementsY).map((element, index)=>{
      observer.observe(element)
    });

    var growElementsX = document.getElementsByClassName("ani-3");
    Object.values(growElementsX).map((element, index)=>{
      observer.observe(element)
    });

    var slideElementsIn = document.getElementsByClassName("ani-4");
    Object.values(slideElementsIn).map((element, index)=>{
      observer.observe(element)
    });

    var expandElements = document.getElementsByClassName("trans-1");
    Object.values(expandElements).map((element, index)=>{
      observer.observe(element)
    });

    var slideElementUp = document.getElementsByClassName("dollar-container");
    Object.values(slideElementUp).map((element, index)=>{
      observer.observe(element)
    });
  }

  getSegmentData(){

    request.get({
      url : metadataURL,
      headers: {
        'Accept-Encoding' : 'gzip',
      },
      encoding : null  // it is very import!!
    }, (err, res, body)=> {
      //check res header it is gzip
      //now body it is gzip stream buffer
      zlib.unzip(body, (err, buffer)=> {
        var tempJSON = JSON.parse(buffer.toString());
        segmentsList = Object.keys(tempJSON).map((segment)=>{
          return(tempJSON[segment])
        });

        var tempID = new URL(window.location).searchParams.get("segment");
        var tempIndex =  segmentsList.findIndex(segment => segment.id === tempID.toUpperCase())
        var prevIndex = (tempIndex === 0)? segmentsList.length - 1 : tempIndex - 1;
        var nextIndex = (tempIndex === segmentsList.length - 1)? 0 : tempIndex + 1;

        this.setState({
          selectedSegmentIndex: tempIndex,
          prevSegment: {
            label: segmentsList[prevIndex].label,
            id: segmentsList[prevIndex].id,
            description: segmentsList[prevIndex].sections.overview.description
          },
          nextSegment: {
            label: segmentsList[nextIndex].label,
            id: segmentsList[nextIndex].id,
            description: segmentsList[nextIndex].sections.overview.description
          }
        });
      });
    });
  }

  detectScroll(e){
    if (e.target.scrollTop > 150 && this.state.topBarState === "") {
      this.setState({
        topBarState: "filled"
      })
      document.getElementById("guide_text").style.top = "-20px";
      document.getElementById("guide_arrow").style.height = 0;
    }
    else if(e.target.scrollTop < 150 && this.state.topBarState !== "") {
      this.setState({
        topBarState: ""
      })
      document.getElementById("guide_text").style.top = 0;
      document.getElementById("guide_arrow").style.height = "98px";

    }

    if (!e.target.className.includes("grid")){
      if ((scrollPosition < e.target.scrollTop) && (this.state.navigationState === "") && scrollPosition > 0) {
        this.setState({
          navigationState: "hidden"
        });
      }

      else if ((scrollPosition > e.target.scrollTop) && (this.state.navigationState !== "")) {
        this.setState({
          navigationState: ""
        });
      }
      scrollPosition = e.target.scrollTop;
    }
  }

  componentWillUnmount(){
    if (!is_ie) {
      PubSub.clear_all();
      window.removeEventListener("keydown", this.arrowListener.bind(this));
      document.getElementById("segment_view").removeEventListener("scroll", this.detectScroll.bind(this), ((supportsPassive)? { passive: true } : false));
    }
  }

  arrowListener(e){
    /*
    //down
    if (e.keyCode === 38) {
      PubSub.publish.update_section_control(-1);
    }
    //up
    else if (e.keyCode === 40) {
      PubSub.publish.update_section_control(1);
    }
    */
    //right
    if (e.keyCode === 39) {
      var nextIndex = (this.state.selectedSegmentIndex === segmentsList.length - 1)? 0 : this.state.selectedSegmentIndex + 1;
      PubSub.publish.segment_selected(segmentsList[nextIndex].id)
    }
    //left
    else if (e.keyCode === 37) {
      var prevIndex = (this.state.selectedSegmentIndex === 0)? segmentsList.length - 1 : this.state.selectedSegmentIndex - 1;
      PubSub.publish.segment_selected(segmentsList[prevIndex].id)
    }
  }

  showContactForm(){

  }

  sharingHandler(selectedButton){
    var shareCopy = "Read about the segment \"" + segmentsList[this.state.selectedSegmentIndex].label + "\" from Spatial.ai. https://taxonomy.spatial.ai/segment?segment=" + segmentsList[this.state.selectedSegmentIndex].id;

    var link = "";
    if (selectedButton === "fb_share_button") {
      link = "https://www.facebook.com/sharer/sharer.php?u=taxonomy.spatial.ai";
    }
    else if(selectedButton === "tw_share_button"){
      link = "https://twitter.com/intent/tweet?text=" + encodeURI(shareCopy);
    }
    else if (selectedButton === "li_share_button") {
      link = "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURI("http://taxonomy.spatial.ai") + "&title=" + encodeURI(shareTitle) + "&summary=" + encodeURI(shareCopy) +"&source=Spatial.ai";
    }
    else if (selectedButton === "email_share_button") {
      document.location = 'mailto:?subject=' + encodeURI(shareTitle) +'&body= ' + encodeURI(shareCopy);
    }
    else if (selectedButton === "link_share_button") {
      this.copyTextToClipboard(shareCopy);
    }

    if (link !== "") {
      window.open(link, 'newwindow', 'width=500,height=300');
    }
  }

  //copy the generated link to the device clipboard
  copyTextToClipboard(copy) {

    var tempElement = document.createElement("textarea");
    tempElement.id = "share_link_copy";
    tempElement.value = copy;
    tempElement.style.width = 0;
    tempElement.style.height = 0;

    document.body.appendChild(tempElement);

    var tempDivObj = document.getElementById("share_link_copy")

    //iOS device
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      var input = tempDivObj;
      var el = input;
      var editable = el.contentEditable;
      var readOnly = el.readOnly;
      el.contentEditable = true;
      el.readOnly = false;
      var range = document.createRange();
      range.selectNodeContents(el);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      el.setSelectionRange(0, 999999);
      el.contentEditable = editable;
      el.readOnly = readOnly;

      try {
        document.execCommand('copy');
        alert('We have copied the link to your clipboard.');
      } catch (e) {
        alert('Oops, unable to copy. Try again please.');
      }
      finally{
        document.body.removeChild(tempDivObj)
      }

    }
    //Non iOS device
    else{
      try {
        tempDivObj.select();
        document.execCommand('copy');
        alert('We have copied the link to your clipboard.');
      }
      catch (err) {
        alert('Oops, unable to copy. Try again please.');
      }
      finally{
        document.body.removeChild(tempDivObj)
      }
    }
  }
}
export default SegmentView;
